/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@font-face {   
    font-family: 'MuseoSans';
    src: url('./assets/fonts/MuseoSans/MuseoSans300.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {   
    font-family: 'MuseoSansRegular';
    src: url('./assets/fonts/MuseoSans/MuseoSans500.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {   
    font-family: 'MuseoSansBold';
    src: url('./assets/fonts/MuseoSans/MuseoSans700.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SFProRegular';
    font-style: normal;
    font-weight: normal;
    src: local('SF Pro Display Regular'), url('./assets/fonts/SfPro/SFPRODISPLAYREGULAR.woff') format('woff');
}
@font-face {
    font-family: 'SFProMedium';
    font-style: normal;
    font-weight: normal;
    src: local('SF Pro Display Medium'), url('./assets/fonts/SfPro/SFPRODISPLAYMEDIUM.woff') format('woff');
}
@font-face {
    font-family: 'SFProBold';
    font-style: normal;
    font-weight: normal;
    src: local('SF Pro Display Bold'), url('./assets/fonts/SfPro/SFPRODISPLAYBOLD.woff') format('woff');
}
@font-face {
    font-family: 'SFProSemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('SF Pro Display Medium'), url('./assets/fonts/SfPro/SFPRODISPLAYMEDIUM.woff') format('woff');
}
@font-face {
    font-family: 'SFProRoundedBold';
    font-style: normal;
    font-weight: normal;
    src: local('SF Pro Display Bold'), url('./assets/fonts/SfPro/SFProRoundedBold.woff') format('woff');
}
@font-face {
    font-family: 'SFProRoundedSemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('SF Pro Rounded Semi bold'), url('./assets/fonts/SfPro/SFProRoundedSemibold.woff') format('woff');
}
@font-face {
    font-family: 'MuseoSansRound500';
    font-style: normal;
    font-weight: normal;
    src: url('./assets/fonts/MuseoSans/MuseoSansRounded500.woff') format('woff');
}
@font-face {
    font-family: 'MuseoSansRound700';
    font-style: normal;
    font-weight: normal;
    src: url('./assets/fonts/MuseoSans/MuseoSansRounded700.woff') format('woff');
}
@font-face {
    font-family: 'SFProLightItalic';
    font-style: normal;
    font-weight: normal;
    src: local('SF Pro Display Light Italic'), url('./assets/fonts/SfPro/SfProDisplayItalics.otf') format('woff');
}
/*@font-face {
    font-family: 'SFCompactRegular';
    font-style: normal;
    font-weight: normal;
    src: url('./assets/fonts/SfCompact/SFCompactRegular.woff') format('woff');
}
@font-face {
    font-family: 'SFCompactBold';
    font-style: normal;
    font-weight: normal;
    src: url('./assets/fonts/SfCompact/SFCompactBold.woff') format('woff');
}*/
//@use "@material/slider";
//@include slider.core-styles;

// Common CSS
html{
    font-size: 18px;
}
body{
    background-color: var(--color-backLightblue);
    font-family: var(--primary-font);
    font-weight:400;
}
ion-content{
    --background:none;
    line-height: normal;
    font-family: var(--primary-font);
    font-weight:400;
    letter-spacing: 0.4px;
}
a{
    text-decoration: none;
}
input[type="radio"], input[type=checkbox]{
    visibility: hidden;
}
/* Header Component */
.search-col ion-input input{
   padding: 0 10px !important;
}
.search-input .searchbar-input, .location-input .searchbar-input{
    background: none !important;
    color: #fff;
    font-size: 0.75rem;
    box-shadow: none;
    padding: 0 10px;
}
.search-col .searchbar-search-icon{
    display:none !important;
}
.search-col .searchbar-clear-button{
    color:#fff;
}
.search-col ul{
    width: auto;
}
.search-input ul li, .location-input ul li{
    color: #000;
    padding: 10px;
}
.location-input .searchbar-clear-button{
    right:0 !important;
}
ion-button{
    text-transform: none;
    --padding-start:0;
    --padding-end:0;
    font-family: var(--primary-font);
    font-weight:400;
    --background-hover: none;
}
.search-input .searchbar-input, .location-input .searchbar-input{
    max-height: 20px;
}

/* Search component */
.search-col ion-auto-complete{
    display: inline-block;
    font-size: 0.75rem;
    position: relative;
    top:7px;
    text-align: left;
}
.search-col ion-button{
    float: right;
    margin: 0;
    width: 30px;
    height: 24px;
}
.search-icon{
    max-width: 40px;
}
/* header search component */
.search-div .search-col ion-button{
    background: #505052;
    border-radius: 0 10px 10px 0;
    max-height: 30px;
}
.search-div .search-col{
    border: 3px solid #505052;
    border-radius: 25px;
    background: #bec0c2;
}
.search-div .search-col ion-auto-complete{
    position: relative;
    top:3px;
}
.search-div .search-icon{
    max-width: 30px;
}
.search-div .search-input{
    border-right:2px solid #fff;
}
/* Result Page */
.search-col .autocomplete-container{
    box-shadow: none;
    height: auto;
}
.search-col .autocomplete-container .input-container input{
    background: none !important;
    color: var(--primary);
    font-size: 0.815rem;
    box-shadow: none;
    padding: 2px 10px;
    height: auto;
    line-height: normal;
    text-overflow: ellipsis;
    font-family: var(--subtitlebold-font);
    letter-spacing: 0.5px;
}
.search-col .autocomplete-container .input-container input::placeholder{
    color: var(--place-holder);
}
.search-input, .location-input{
    width:40% !important;
    padding: 4px 0;
}
.search-input{
    width:55% !important;
}
.findcare-search .search-col{
    border: 1px solid var(--border);
    padding: 4px;
}
.search-col .autocomplete-container .not-found{
    position: absolute;
    width: 100%;
    color: #000;
}
.search-col .autocomplete-container .suggestions-container ul li a{
    text-align: left;
    padding: 10px 20px 0;
    font-size: 0.75rem;
}
.search-col .autocomplete-container .suggestions-container ul li a:last-child{
    padding-bottom: 10px;
}
.search-col .location-input .autocomplete-container .suggestions-container ul li:first-child{
    padding-top:5px;
}
.search-col .location-input .autocomplete-container .suggestions-container ul li:last-child{
    padding-bottom:5px;
}
.search-col .location-input .autocomplete-container .suggestions-container ul li a{
    font-size: 0.975rem;
    color: var(--primary);
    font-family: var(--subtitlebold-font);
    letter-spacing: 0.44px;
    padding: 5px 10px;
}
.lang-dropdown .p-dropdown .p-dropdown-label.p-placeholder{
    font-size: 0.75rem;
    padding: 3px 10px;
    color:#fff;
}
.lang-dropdown .p-dropdown{
    background-color: #aaacaf;
    border: none;
    border-radius: 0;
}
.lang-dropdown .p-dropdown-items img, .lang-dropdown .country-item img{
    width: 15px;
    display: inline-block;
    margin-right: 10px;
}
.lang-dropdown .p-dropdown-items .country-item div, .lang-dropdown .country-item div{
    display: inline-block;
    position: absolute;
    top: -1px;
    font-size:0.75rem;
}
.lang-dropdown .p-dropdown .p-dropdown-trigger{
    color:#fff;
}
.lang-dropdown .p-dropdown-panel .p-dropdown-items{
    padding:0;
    font-size:0.75rem;
}
.lang-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item{
    padding: 3px 10px 0px 10px;
}
.lang-dropdown .p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover{
    background: #7a7c7d;
    color: #fff;
}
.lang-dropdown .p-dropdown:focus {
    outline:none;
    box-shadow: none;
}
.lang-dropdown .p-dropdown:not(.p-disabled).p-focus{
    outline:none;
    box-shadow: none;
}
.p-dropdown .p-dropdown-label{
    padding: 3px 10px;
}
.modal-class{
    --background: #d3d3d3;
}
.modal-class .modal-wrapper{
    min-height: 400px;
    height: auto;
} 
.autocomplete-container .input-container .x{
    display: none;
}


//Header search new
.search-input, .location-input{
    display: inline-block;
    width: 200px;
    padding: 3px 0;
}
.search-col{
    background-color: var(--color-white);
    border-radius: 5px;
    float: left;
    width: 100%;
}
.search-col .search-input .autocomplete-container .input-container{
    border-right: 1.5px solid var(--border);
}
.search-col .autocomplete-container .input-container input::placeholder {
    color: var(--input-placeholder);
    font-family: var(--subtitle-font);
}
.loader{
    text-align:center;
    padding:100px !important;
}
.no-data{
    padding:100px 20px;
    color: #ff0000;
    text-align: center;
    font-size: 1.25rem;
}
.pagination button{
    margin: 0 !important;
    height: 25px !important;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight{
    border:none;
    font-weight: bold;
    background: none;
}
.p-paginator-element:focus{
    box-shadow: none;
}
.p-paginator .p-paginator-pages .p-paginator-page, .pi{
    font-size: 0.875rem;
}
.p-paginator{
    padding: 5px;
    background: none;
}
.slide-bar .p-dropdown .p-dropdown-label, .sort-by .p-dropdown .p-dropdown-label{
    font-size: 0.75rem;
    padding: 0 5px 1px 5px;
    color: var(--color-black);
    font-family: var(--subtitle-font);
}
.slide-bar .pi, .sort-by .pi{
    font-size: 0.75rem;
    color: var(--color-black);
}
.slide-bar .p-dropdown, .sort-by .p-dropdown{
    background-color: var(--color-pagelight);
    border: none;
}
.slide-bar .p-dropdown-panel .p-dropdown-items, .sort-by .p-dropdown-panel .p-dropdown-items{
    padding: 0;
}
.slide-bar .p-dropdown-panel .p-dropdown-items .p-dropdown-item, .sort-by .p-dropdown-panel .p-dropdown-items .p-dropdown-item{
    padding: 2px 10px;
    font: 0.75rem var(--subtitle-font);
}
.slide-bar .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight, .sort-by .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight{
    background: var(--color-gray);
    color: #fff;
}
.slide-bar .p-dropdown:not(.p-disabled).p-focus, .sort-by .p-dropdown:not(.p-disabled).p-focus{
    box-shadow: none;
    outline: none;
}
.sort-by .p-dropdown{
    background-color: var(--color-dropdown);
    padding: 3px;
    border-radius: 8px;
    text-align: left;
    min-width: 110px;
}
.sort-by .p-dropdown .p-dropdown-trigger{
    width: auto;
    padding-right: 5px;
}
.header-md::after{
    height: 0;
}
.mq-result{
    font-size: 0.75rem;
}
.mq-result-icon{
    display: none;    
}
.mq-dropdown-menu{
    width: 300px;
    right: 0 !important;
    left:auto !important;
}
.mq-icon-clear{
    right: -5px;
    top: -2px;
}
.mq-icon-clear{
    display: none;
}
.page .p-dropdown .p-dropdown-label, .page .pi{
    color: var(--color-searchtext);
    font-weight: 400;
    font-size: 0.875rem;
}
.page .p-dropdown{
    background-color: var(--color-searchbar);
    padding: 3px;
    border-radius: 5px;
}
.pagination .p-paginator .p-paginator-pages .p-paginator-page.p-highlight{
    border: none;
    background: var(--primary);
    color: #fff !important;    
}
.pagination .p-paginator .p-paginator-pages .p-paginator-page{
    border-radius: 50%;
    padding: 12px 10px;
    min-width: 20px;
    color:var(--primary);   
}
.search-col{
    display: block;
}
.p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last{
    border-radius: 50%;
    padding: 12px 10px;
    min-width: 20px;
    color: var(--primary);
}
.custom-alert button{
    background: var(--primary-dark) !important;
    color: #fff !important;
    padding: 5px 15px !important;
    font-size: 0.975rem !important;
    border-radius: 5px !important;
    margin: 10px !important;
    min-width: 0 !important;
    min-width: initial !important;
    font-weight: normal !important;
    max-width: 45%;
    border: none !important;
    text-transform: uppercase !important;
    font-family: var(--subtitle1-font);
    letter-spacing: 0.4px;
}
.reset-alert button{
    font-size: 0.975rem;
}
.reset-alert .cancel-btn{
    background: none !important;
    color: var(--primary) !important;
    border: 1px solid var(--filterchip-border) !important;
}
.reset-alert .cancel-btn:hover{
    color:#fff !important;
}
.custom-alert button:hover{
    background: var(--button-hover) !important;
    //font-family: 'MuseoSansBold';
}
.custom-alert .alert-button-group{
    margin-bottom: 10px;
    padding: 10px;
    justify-content: center;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button{
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button{
    background: #6f6f6f;
}
.p-link:focus{
    box-shadow: 0 0 0 0.2rem #d6d6d6;
}
.p-carousel .p-carousel-content .p-carousel-prev, .p-carousel .p-carousel-content .p-carousel-next{
    display: none;
}
.p-carousel .p-carousel-indicators{
    padding: 0;
}
.provider .p-carousel .p-carousel-indicators, .map-card .p-carousel .p-carousel-indicators{
    display: none;
}
.map-card .p-carousel-items-container{
    align-items: flex-end;
}
.provider .p-carousel .p-carousel-content .p-carousel-next{
    border-radius: 3px;
    background: var(--color-filterblue);
    color: #fff;
    width: 1.8rem;
    margin-right: 0;
    display: block;
    text-align: center;
}
.provider .p-carousel .p-carousel-content .p-carousel-next .pi{
    font-size: 1.2rem;
    font-weight: 700;
}
.p-carousel .p-carousel-content .p-carousel-next:focus{
    box-shadow: none;
}
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover{
    background: var(--color-filterblue);
    color: #fff;
}
.provider .noArrow .p-carousel .p-carousel-content .p-carousel-next{
    display: none;
}
.actionsheet-favorite .action-sheet-button{
    border-bottom: 1px solid #efefef;
}
.actionsheet-favorite .favBtnIcon, .actionsheet-favorite .favBtnIcon .action-sheet-icon{
    color: var(--color-btncolor);
}
.actionsheet-favorite .action-sheet-button-inner{
    justify-content: center;
}
.filter-itembody .autocomplete-container .input-container input{
    background: var(--color-sortbgcolor);
    height: 30px;
    padding-right: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.filter-itembody .autocomplete-container{
    box-shadow: none;
    height: 30px;
}
.filter-itembody .autocomplete-container .suggestions-container ul li a{
    color: #000;
    font-weight: 700;
}
.filter-itembody .autocomplete-container .suggestions-container ul li a b{
    font-weight: 100;
 }
.filter-itembody .autocomplete-container .suggestions-container{
    border-radius: 5px;
}
.saml-btn button{
    background-color: #5bc0de !important;
    color: #fff !important;
}
.alertmsg-button{
    background-color: var(--color-btncolor) !important;
    padding: 5px 20px !important;
    color: #fff !important;
    margin-bottom: 10px !important;
}
.s-result h4 b{
    font-family: 'MuseoSans';
    font-weight: normal;
}
.search-col .search-input .autocomplete-container .suggestions-container{
    top: 22px;
    box-shadow: 0px 0px 1px #00000040;
}
.findcare-search .location-input .autocomplete-container .suggestions-container{
    top: 26px;
}
.findcare-search .search-input .autocomplete-container .suggestions-container{
    width: 500px;
    top: 26px;
}
.search-input .autocomplete-container .suggestions-container ul li{
    cursor: default;
}
.detail-pagination{
    .p-paginator .p-paginator-first, .p-paginator .p-paginator-prev, .p-paginator .p-paginator-next, .p-paginator .p-paginator-last{
        display: none;
    }
    .p-paginator .p-paginator-pages .p-paginator-page, .pi{
        font-size: 0.875rem;
        min-width: auto;
        color: var(--icon-hover);
        background: #fff;
        font-weight: 700;
        margin: 0 5px;
        height: auto;
        padding: 7px 10px;
        border-radius: 5px;
        border: 1px solid var(--filterchip-border);
    }
    .p-link:focus{
        box-shadow: none;
    }
    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight{
        color:#fff;
        background: var(--icon-hover);
        border: 1px solid var(--icon-hover);
        box-shadow: -4px 4px 8px var(--favorite);
    }
}
.popup{
    cursor: pointer;
}
ion-alert{
    --min-width:400px !important;
    font-family: 'Roboto' !important;
}
.custom-alert h2{
    color: var(--desc-text) !important;
    font-family: 'Roboto';
    font-size: 1.2rem !important;
    font-weight: normal !important;
}
.custom-alert .alert-message, .signout-alert .alert-message{
    color: var(--desc-text) !important;
    padding: 0 15px !important;
    font-size: 1.2rem;
    font-family: var(--subtitle-font);
    font-weight: normal !important;
    letter-spacing: 0.4px;
}
.err-alert .alert-message{
    font-size: 1rem;
    color: var(--error-text) !important;
}
.reset-alert .alert-message{
    font-size: 1rem;
}
.custom-alert .high-light{
    color:var(--favorite);
    font-weight: 500;
}
.err-alert .alert-wrapper{
    border: 1px solid var(--primary);
    box-shadow: -2px 2px 4px var(--primary);
}
.signout-alert .alertmsg-button{
    background: var(--button) !important;
    color: #fff !important;
    padding: 5px 15px !important;
    font-size: 1.03rem !important;
    border-radius: 5px !important;
    text-transform: capitalize !important;
    font-family: 'Roboto';
}
.signout-alert .alertmsg-button:hover{
    background: var(--button-hover) !important;
}
.sim-score{
    background: var(--background-gray);
    border-radius: 12px;
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-left: auto;
    color: var(--sub-title);
    min-width: 65px;
    justify-content: center;
    padding: 5px 0 !important;
}
.sim-score span:first-child{
    height: 20px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 1rem;
    letter-spacing: 0.13px;
    line-height: 1.3;
    margin-right: 2px;
}
.sim-score .img{
    color: var(--secondary);
    font-size: 20px !important;
    width: 20px;
    height: 20px;
    line-height: inherit;
}
ion-skeleton-text{
    --border-radius: 0;
}
.skel-img{
    width: 50px;
    height: 50px;
    --border-radius:50%;
}
.skel-lh{
    line-height: 1.3;
}
.skel-icon{
    width: 20px;
    height: 20px;
    --border-radius:50%;
}
.mapview.leaflet-container a.pro-name{
    color: var(--findcare-button);
    cursor: pointer;
    font-family: var(--primary-font);
    font-size: 1.2rem;
}
.mapview.leaflet-container .pro-special{
    color: var(--search-name);
    font-family: var(--primary-font);
    font-size: 0.875rem;
    margin-bottom: 5px;
}
.acc-icon{
    font-size: 1rem;
    color: var(--icon-color);
}
.stop-url{
    color: var(--button) !important;
}
.organization-input{
    .autocomplete-container{
        box-shadow: none;
        margin: 5px 0 0px;
    }
    .autocomplete-container .not-found{
        border: none;
    }
    .autocomplete-container .input-container input{
        border: 1px solid var(--border-gray);
        border-radius: 5px;
        padding: 0 10px;
        height: 33px;
        color: var(--search-text);
        letter-spacing: 0.4px;
    }
    .autocomplete-container .input-container input:hover, .autocomplete-container .input-container input:focus{
        border: 1px solid var(--border-hover);
    }
    .autocomplete-container .input-container input::placeholder {
        color: var(--place-holder);
        opacity: 0.5;
    }
    .autocomplete-container .suggestions-container ul li a{
        padding:10px;
        font-size: 0.975rem;
        font-family: 'SFProRegular';
    }
    .autocomplete-container .suggestions-container{
        z-index: 11;
    }
    .error-auto .autocomplete-container .input-container input{
        border: 1px solid var(--error-text);
    }
}
.signin-input{
    .p-dropdown{
        width: 100%;
        border: 1px solid var(--border-gray) !important;
        outline: 0;
        box-shadow: none !important;
        height: 35px;
    }
    .error-drop .p-dropdown{
        border: 1px solid var(--error-text) !important;
    }
    .p-dropdown:hover, .p-dropdown:focus{
        border: 1px solid var(--border-hover) !important;
    }
    .p-dropdown .p-dropdown-label{
        color: var(--desc-text);
        font-family: var(--subtitle1-font);
        padding: 7px 10px;
        font-weight: 500;
        font-size: 0.975rem;
    }
    .pi{
        font-size: 1.2rem;
        color: var(--desc-text);
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item{
        color: var(--desc-text);
        font-family: "SFProRegular";
        font-size: 0.975rem;
    }
} 
.spec-search{
    --padding-start: 10px;
    --inner-padding-end: 5px;
    --min-height: 30px;
    border-radius: 5px;
    --background: #fff;
    margin-top: 15px;
    --highlight-background: none;
    --border-width: 0;
    --padding-top: 3px;
    --padding-bottom: 3px;
    --inner-border-width: 0;
    margin-bottom: 10px;
    border: 1px solid var(--border);
}
.spec-search ion-input{
    --padding-start: 5px;
    font-size: 0.875rem;
    --padding-bottom: 5px;
    --padding-top: 5px;
    color: var(--primary-dark);
    --placeholder-color:var(--input-placeholder);
    font-family: var(--subtitle-font);
    letter-spacing: 0.15px;
    font-size: 1rem;
}
.spec-search ion-icon, .spec-search .img{
    color: var(--place-holder);
    font-size: 1.25rem;
    margin-right: 5px;
}
.spec-search:hover{
    //border: 1px solid var(--border-hover);
    ion-icon, .img{
        color: var(--search-text);
    }
}
.spec-search .remove{
    color:var(--text-black);
    cursor: pointer;
}
.spec-search .remove:hover{
    color:var(--remove-hover);
}
.spec-search .dark{
    color:var(--search-text);
}
.spec-search svg{
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.profile-dropdown{
    .p-dropdown{
        height: 36px;
        padding: 2px 0;
        border-radius: 5px;
        width: 100%;
    }
    .p-dropdown-items{
        padding: 0 !important;
    }
    .p-dropdown-panel .p-dropdown-items{
        font-family: var(--subtitle-font);
        font-size: 0.915rem;
    }
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight{
        background: var(--filter-chip);
    }
    .p-dropdown:not(.p-disabled).p-focus{
        outline:none;
        box-shadow: none;
    }
    .p-dropdown .p-dropdown-label{
        padding: 6px 10px;
        font-family: var(--subtitle-font);
        font-size: 0.915rem;
    }
    .p-dropdown:not(.p-disabled):hover{
        border: 1px solid var(--primary);
    }
}
.error-dropdown .p-dropdown{
    border: 1px solid var(--error-text);
}
.pro-search:hover{
    border: 1px solid var(--primary);
}
.primary-search .autocomplete-container .suggestions-container{
    margin-left: -20px;
    margin-top: 9px;
    width: 110%;
}
.primary-search .autocomplete-container .not-found{
    border: none;
}
.pro-search ion-button{
    margin-right: -10px;
}
.app-search .autocomplete-container .suggestions-container{
    margin-left: -20px;
    width: 105%;
}
.rate-dialog .p-dialog .p-dialog-header .p-dialog-title{
    font-weight: 500;
    color: var(--title);
    font-family: var(--subtitle1-font);
    letter-spacing: 0.4px;
}
ion-input{
    min-height: auto !important;
}
.menu-content-open{
    pointer-events: unset;
}
.search-bar .search-label{
    padding-bottom: 3px;
    display: block;
    color: var(--primary-dark);
    font-family: var(--subtitle-font);
    font-size: 1.125rem;
    letter-spacing: 0.15px;
    opacity: 1;
}
.search-bar .spec-search{
    margin-top: 0;
    --min-height: 34px;
    border-radius: 8px;
}
.search-bar .spec-search ion-input{
    color:var(--input-icon);
}
.search-bar .spec-search ion-icon, .search-bar .spec-search .img{
    margin-right: 5px;
    height: 17px;
    width: 17px;
    color: var(--input-icon);
}
.search-bar .spec-search:hover{
    border: 1px solid var(--border);
}
.spec-search{
    ion-icon:hover, .img:hover{
        color: var(--input-icon-hover) !important;
    }
}
.didknow-cont a{
    color: var(--primary);
}
.didknow-cont a:hover{
    text-decoration: underline;
}
.tip-alert .alert-wrapper{
    min-width: 600px;
}
.tip-alert textarea{
    height: 100px;
    border: 1px solid var(--border) !important;
    padding: 10px !important;
    border-radius: 5px;
}
.tip-msg a, .tip-area a{
    color: var(--input-icon-hover);
}
.tip-msg a:hover{
    text-decoration: underline;
}
.settings-locat .autocomplete-container .input-container input{
    color: #000;
    font-family: var(--subtitle-font);
}
.settings-locat:hover{
    border: 1px solid var(--primary);
}
.compare-faci{
    .p-multiselect .p-multiselect-label{
        font-size: 0.815rem;
    }
    .p-multiselect.p-component{
        width: 100%;
        font-family: var(--primary-font);
    }
    .p-multiselect.p-component .p-multiselect-item{
        font-family: var(--primary-font);
        font-size: 0.815rem;
    }
    .p-checkbox, .p-checkbox .p-checkbox-box{
        width: 15px;
        height: 15px;
    }
    .p-checkbox .p-checkbox-box .p-checkbox-icon{
        font-size: 10px;
    }
    .p-checkbox .p-checkbox-box.p-highlight{
        border-color: var(--page-title);
        background: var(--page-title);
    }
}
.sdoh-div{
    .p-dropdown{
        width: 200px;
    }
    .p-dropdown .p-dropdown-label.p-placeholder, .p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message, .p-dropdown-panel .p-dropdown-items .p-dropdown-item{
        font-family: var(--primary-font);
    }
    .p-inputtext{
        padding: 3px 10px;
    }
}
.sdoh-div .p-datatable{
    font-family: var(--primary-font);
}
/*.sdoh-table-div .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th{
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
}
.sdoh-table-div .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td{
    width: 300px;
}
.sdoh-table-div .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:nth-child(-n+3){
    max-width: 100px !important;
}
.sdoh-table-div .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:nth-child(-n+3){
    max-width: 100px !important;
    overflow: hidden;
}
.sdoh-table-div .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:first-child, .sdoh-table-div .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:first-child{
    max-width: 150px !important;
}*/
.sdoh-div .p-datatable-scrollable .p-datatable-wrapper{
    max-height: 400px !important;
}
.sdoh-div .p-datatable .p-datatable-tbody > tr > td{
    padding: 15px 10px;
}
.sdoh-table-div{
    .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td, .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th{
        width: 100px;
    }
    .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:nth-child(-n+2), .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:nth-child(-n+2){
        min-width: 300px;
        max-width: 300px;
        overflow: hidden;
    }
}
.sdoh-variable{
    .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:nth-child(3), .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:nth-child(3) {
        max-width: 100px !important;
    }
    .p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:nth-child(1), .p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:nth-child(1){
        max-width: 300px !important;
        overflow: hidden;
    }
} 
@media only screen and (max-width: 1500px){
    html{
        font-size: 16px;
    }
}
@media only screen and (max-width: 1200px){
    .search .location-input{
        width: 35% !important;
    }
    // html{
    //     font-size: 16px;
    // }
}
@media only screen and (max-width: 1100px) {
    // html{
    //     font-size: 0.91rem;
    // }
    .search .search-input .autocomplete-container .suggestions-container{
        width: 380px;
    }
}
@media only screen and (max-width: 968px) {
    .search .search-input .autocomplete-container .suggestions-container{
        width: 100%;
    }
    // html{
    //     font-size: 16px;
    // }
}
@media only screen and (max-width: 768px) {
    .findcare-search .search-input{
        width: 50% !important;
    }
}
@media only screen and (max-width: 640px) {
    html{
        font-size: 15px;
    }
    .tip-alert .alert-wrapper{
        min-width: 400px;
    }
}
@media only screen and (max-width: 576px) {
    .findcare-search .search-input .autocomplete-container .suggestions-container{
        width: 200%;
    }
    ion-alert{
        --min-width:auto !important;
    }
}
@media only screen and (max-width: 400px) {
    html{
        font-size: 14px;
    }
    .tip-alert .alert-wrapper{
        min-width: 100%;
    }
}